import { useApi } from "@/composition/useApi.js";

export default {
  state: {
    spinnerChart: false,
    tableData: [],
    chartData: [],
    objects: [],
    timeIntervalChart: "DAY",
    imageCache: {},
  },
  mutations: {
    setSpinnerChart(state, data) {
      state.spinnerChart = data;
    },
    setChartData(state, data) {
      state.chartData = data;
    },
    setTableData(state, data) {
      state.tableData = data;
    },
    SetObjects(state, data) {
      state.objects = data;
    },
    setTimeIntervalChart(state, interval) {
      state.timeIntervalChart = interval;
    },
    setImageCache(state, { key, data }) {
      state.imageCache[key] = data;
      if (Object.keys(state.imageCache).length > 5) {
        const oldValue = Object.keys(state.imageCache)[0];
        delete state.imageCache[oldValue];
      }
    },
  },
  actions: {
    getAnprEventAnalitics({ commit, state }, { companyId }) {
      const { api } = useApi();
      commit("setSpinnerChart", true);
      return api
        .get("/anpr-events/analitics", {
          params: {
            timeInterval: state.timeIntervalChart,
            companyId,
          },
        })
        .then(r => {
          if (r.status === 200) {
            commit("setChartData", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
        })
        .finally(() => {
          commit("setSpinnerChart", false);
        });
    },
    updateTimeIntervalChart({ commit, dispatch }, { interval, companyId }) {
      commit("setTimeIntervalChart", interval);
      dispatch("getAnprEventAnalytics", companyId);
    },
    searchAllAnprEvents({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/anpr-events/search", body)
        .then(r => {
          if (r.status === 200) {
            commit("setTableData", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
          commit("setTableData", []);
        });
    },
    searchTrackObjects({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/objects/search", body)
        .then(r => {
          if (r.status === 200) {
            commit("SetObjects", r.data);
          }
        })
        .catch(e => {
          console.error("Ошибка:", e);
          commit("SetObjects", []);
        });
    },
    updateAnprEvent(ctx, { id, body }) {
      const { api } = useApi();
      return api.put(`/anpr-events/${id}`, body);
    },
    deleteAnprEvent(ctx, id) {
      const { api } = useApi();
      return api.delete(`/anpr-events/${id}`);
    },
    getImage({ state, commit }, imageUrl) {
      const { api } = useApi();
      if (state.imageCache[imageUrl]) {
        return state.imageCache[imageUrl];
      }
      return api.get(imageUrl, { responseType: "blob" }, "S3_URL").then(res => {
        const urlCreator = window.URL || window.webkitURL;
        const imageSrc = urlCreator.createObjectURL(new Blob([res.data]));
        commit("setImageCache", { key: imageUrl, data: imageSrc });
        return imageSrc;
      });
    },
  },
};
