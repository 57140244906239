import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/admin/:activeItem?",
    name: "AdminPage",
    component: () => import("@/views/AdminPage.vue"),
  },
  {
    path: "/",
    redirect: "/schemes",
  },
  {
    path: "/:notFound(.*)",
    redirect: "/schemes",
  },
  {
    path: "/login",
    name: "loginPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/autorizationPageComponent/AutorizationPage.vue"
      ),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: `/schemes/:id?`,
    name: "schema",
    component: () => import("@/views/MainPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: `/analytics/:id?`,
    name: "analytics",
    component: () => import("@/views/AnalyticsPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: `/analytics/:id/anpr-report`,
    name: "ReportPage",
    component: () => import("@/views/ReportPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
